var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('MultiChartSettingsStyled', [_c('div', {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.$tc('widgetTypes.MULTI_CHART')))]), _c('div', {
    staticClass: "row"
  }, [_c('BasicSelect', {
    staticClass: "select",
    attrs: {
      "target": _vm.$t('graphTypes.placeholder'),
      "options": _vm.chartTypeOptions,
      "selected": _vm.selectedGraphType
    },
    on: {
      "change": _vm.updateChartType
    }
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }