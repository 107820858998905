var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('AssetDimensionWrapperStyled', {
    class: {
      expanded: _vm.isExpanded
    }
  }, [_vm.isDraggable ? _c('AssetDimensionIndexStyled', [_c('CountCircle', {
    staticClass: "handle",
    attrs: {
      "count": _vm.index + 1
    }
  })], 1) : _vm._e(), _c('AssetDimensionWidgetWrapperStyled', [_c('AssetDimensionStyled', {
    class: {
      removeable: _vm.isRemoveable
    },
    attrs: {
      "disabled": !_vm.isSelectable
    },
    on: {
      "click": _vm.click
    }
  }, [_vm.isExpandable && _vm.isMultiChart ? _c('ChevronRightIcon') : _vm.isCollapsable && _vm.isMultiChart ? _c('ChevronDownIcon') : _vm.isAddable ? _c('PlusIcon') : _vm.isRemoveable ? _c('MinusIcon') : _vm._e(), _c('span', {
    staticClass: "name"
  }, [_vm._v(" " + _vm._s(_vm.assetDimensionName) + " " + _vm._s(_vm.dimensionUnit) + " ")]), _vm.showCount ? _c('CountCircle', {
    staticClass: "circle",
    attrs: {
      "count": _vm.dimension.usedInDashboardCount
    }
  }) : _vm._e(), _vm.canRemove ? _c('button', {
    staticClass: "remove",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.$emit('removeDimension');
      }
    }
  }, [_c('XIcon')], 1) : _vm._e()], 1), _c('transition', {
    attrs: {
      "name": "fade-in-down"
    }
  }, [_vm.isExpanded && _vm.isMultiChart ? _c('div', {
    staticClass: "settings"
  }, [_vm.canConfigureTargetValue ? [_c('div', {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm._f("capitalize")(_vm.$tc('targetValue', 1))))]), _c('div', {
    staticClass: "target-value"
  }, [_c('OnOffToggle', {
    staticClass: "toggle",
    attrs: {
      "alternativeStyle": true,
      "value": _vm.targetValueEnabled
    },
    on: {
      "toggle": function toggle($event) {
        return _vm.$emit('toggleTargetValueEnabled');
      }
    }
  }), _c('input', {
    ref: "targetValue",
    attrs: {
      "type": "number",
      "placeholder": 0
    },
    domProps: {
      "value": _vm.targetValue
    },
    on: {
      "input": _vm.updateTargetValue
    }
  })], 1)] : _vm._e(), _vm.isMultiChart ? _c('MultiChartSettings', {
    attrs: {
      "widgetDimension": _vm.widgetDimension
    },
    on: {
      "updateChartType": function updateChartType($event) {
        return _vm.$emit('updateChartType', $event);
      }
    }
  }) : _vm._e()], 2) : _vm._e()])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }