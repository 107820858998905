<template>
  <AssetDimensionWrapperStyled :class="{ expanded: isExpanded }">
    <AssetDimensionIndexStyled v-if="isDraggable">
      <CountCircle class="handle" :count="index + 1" />
    </AssetDimensionIndexStyled>
    <AssetDimensionWidgetWrapperStyled>
      <AssetDimensionStyled
        @click="click"
        :class="{
          removeable: isRemoveable,
        }"
        :disabled="!isSelectable"
      >
        <ChevronRightIcon v-if="isExpandable && isMultiChart" />
        <ChevronDownIcon v-else-if="isCollapsable && isMultiChart" />
        <PlusIcon v-else-if="isAddable" />
        <MinusIcon v-else-if="isRemoveable" />
        <span class="name"> {{ assetDimensionName }} {{ dimensionUnit }} </span>
        <CountCircle class="circle" v-if="showCount" :count="dimension.usedInDashboardCount" />
        <button class="remove" v-if="canRemove" @click.stop="$emit('removeDimension')">
          <XIcon />
        </button>
      </AssetDimensionStyled>
      <transition name="fade-in-down">
        <div class="settings" v-if="isExpanded && isMultiChart">
          <template v-if="canConfigureTargetValue">
            <div class="title">{{ $tc('targetValue', 1) | capitalize }}</div>
            <div class="target-value">
              <OnOffToggle
                :alternativeStyle="true"
                class="toggle"
                :value="targetValueEnabled"
                @toggle="$emit('toggleTargetValueEnabled')"
              />
              <input type="number" ref="targetValue" :placeholder="0" :value="targetValue" @input="updateTargetValue" />
            </div>
          </template>
          <MultiChartSettings v-if="isMultiChart" :widgetDimension="widgetDimension" @updateChartType="$emit('updateChartType', $event)" />
        </div>
      </transition>
    </AssetDimensionWidgetWrapperStyled>
  </AssetDimensionWrapperStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import chroma from 'chroma-js'

import get from 'lodash/get'

import { CountCircle, OnOffToggle } from '@common/components'

import { flexCenter, buttonReset } from '@styles/mixins'

import { ChevronRightIcon, ChevronDownIcon, PlusIcon, MinusIcon, XIcon } from 'vue-feather-icons'

import MultiChartSettings from './AssetDimension/MultiChartSettings'
import localesMixin from '@/mixins/locales'
import { getAssetDimensionNameByLocale } from '@common/utils/src'

const AssetDimensionWrapperStyled = styled('div')`
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5rem;
  .settings {
    margin: 0 0.5rem;
    padding: 0.5rem;
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => (theme.isDark ? theme.colors.darkerGrey : theme.colors.primaryDarkest)};
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    .title {
      font-weight: bold;
      margin-bottom: 0.5rem;
    }
    .target-value {
      display: flex;
      .toggle {
        margin-right: 1rem;
      }
    }
  }
`
const AssetDimensionWidgetWrapperStyled = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
`
const AssetDimensionIndexStyled = styled('span')`
  user-select: none;
  justify-content: space-between;
  padding: 0.25rem;
  border-radius: 3px;
  color: ${p => p.theme.colors.white};
  z-index: 10;
  padding-top: 0;
  .handle {
    cursor: move;
    font-size: 12px;
    font-weight: bold;
    height: 32px;
    width: 32px;
  }
`
const AssetDimensionStyled = styled('button')`
  ${buttonReset}
  ${flexCenter}
  width: 100%;
  user-select: none;
  justify-content: space-between;
  padding: 0.25rem;
  border-radius: 3px;
  color: ${p => p.theme.colors.white};
  background: linear-gradient(
    to right,
    ${p => (p.theme.isDark ? p.theme.colors.darkGrey : p.theme.colors.primary)} 0%,
    ${p => (p.theme.isDark ? p.theme.colors.darkGrey : p.theme.colors.darkestGrey)} 150%
  );
  height: 2rem;
  z-index: 10;

  > .feather {
    width: 1rem;
    margin-right: 0.5rem;
  }

  .name {
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .circle {
    color: ${p => p.theme.colors.white};
    background: ${p => chroma(p.theme.colors.white).alpha(0.2).css()};
  }

  .remove {
    ${buttonReset}
    ${flexCenter}
    padding: 0;
    color: ${p => p.theme.colors.white};
    opacity: 0.3;
    &:hover {
      color: ${p => p.theme.colors.white};
      opacity: 1;
    }
  }

  &.removeable {
    opacity: 0.5;
  }

  &:not([disabled]) {
    &:hover {
      opacity: 1;
      &.removeable {
        opacity: 0.75;
      }
      color: ${p => p.theme.colors.white};
      background: linear-gradient(
        to right,
        ${p => (p.theme.isDark ? p.theme.colors.primaryDarker : p.theme.colors.primary)} 0%,
        ${p => (p.theme.isDark ? p.theme.colors.primaryDarker : p.theme.colors.primary)} 150%
      );
      background-position: 100%;
    }
  }

  &:disabled {
    color: ${p => (p.theme.isDark ? p.theme.colors.lightGrey : p.theme.colors.white)};
    background: ${p => (p.theme.isDark ? p.theme.colors.mediumGrey : p.theme.colors.muted)};
    opacity: ${({ theme }) => (theme.isDark ? 0.3 : 0.6)};
  }
`

export default {
  mixins: [localesMixin],
  inject: ['uiSettings'],
  props: {
    dimension: {
      type: Object,
      required: true,
    },
    selectedWidget: {
      type: Object,
    },
    showCount: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
    },
  },
  components: {
    AssetDimensionWrapperStyled,
    AssetDimensionStyled,
    MultiChartSettings,
    CountCircle,
    OnOffToggle,
    ChevronRightIcon,
    ChevronDownIcon,
    PlusIcon,
    MinusIcon,
    XIcon,
    AssetDimensionIndexStyled,
    AssetDimensionWidgetWrapperStyled,
  },
  data() {
    return {
      isExpanded: false,
      canConfigureTargetValue: false,
    }
  },
  computed: {
    locale() {
      const userLocale = this.uiSettings?.dates ?? 'DE_DE'
      const replacedDates = userLocale.replace('_', '-')
      return replacedDates.slice(0, 2)
    },
    assetDimensionName() {
      if (this.dimension?.nameTranslations.length === 0) {
        return this.dimension.name
      }
      return getAssetDimensionNameByLocale(this.dimension?.nameTranslations, this.locale)
    },
    isMultiChart() {
      return get(this.selectedWidget, 'name', 'unkown') === 'MULTI_CHART'
    },
    widgetDimension() {
      return get(this.dimension, 'widgetDimension')
    },
    targetValue() {
      return get(this.dimension, 'widgetDimension.targetValue')
    },
    targetValueEnabled() {
      return get(this.dimension, 'widgetDimension.targetValueEnabled')
    },
    isAddable() {
      return this.dimension.isEnabled && !this.dimension.isSelected && !this.dimension.isConfigurable
    },
    isRemoveable() {
      return this.dimension.isSelected && !this.dimension.isConfigurable
    },
    isExpandable() {
      return this.dimension.isConfigurable && !this.isExpanded
    },
    isCollapsable() {
      return this.dimension.isConfigurable && this.isExpanded
    },
    isDraggable() {
      return this.dimension.isConfigurable
    },
    canRemove() {
      return this.dimension.isConfigurable
    },
    isSelectable() {
      return this.canRemove || this.isRemoveable || this.isAddable
    },
    dimensionUnit() {
      return this.dimension.physicalUnitUIImperial == null || this.dimension.physicalUnitUIImperial === ''
        ? ''
        : `[${this.selectedUIUnitSystem === 'IMPERIAL' ? this.dimension.physicalUnitUIImperial : this.dimension.physicalUnitUIMetric}]`
    },
  },
  methods: {
    updateTargetValue() {
      const el = this.$refs.targetValue
      const value = parseFloat(el.value)
      if (!Number.isNaN(value)) {
        this.$emit('updateTargetValue', value)
      }
    },
    click() {
      if (this.isAddable) {
        this.$emit('addDimension', this.dimension)
        return
      }
      if (this.isRemoveable) {
        this.$emit('removeDimension', this.dimension)
        return
      }
      if (this.dimension.isConfigurable) {
        this.isExpanded = !this.isExpanded
      }
    },
  },
}
</script>
