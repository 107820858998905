<template>
  <MultiChartSettingsStyled>
    <div class="title">{{ $tc('widgetTypes.MULTI_CHART') }}</div>
    <div class="row">
      <BasicSelect
        class="select"
        :target="$t('graphTypes.placeholder')"
        :options="chartTypeOptions"
        :selected="selectedGraphType"
        @change="updateChartType"
      />
    </div>
  </MultiChartSettingsStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

import get from 'lodash/get'

import { BasicSelect } from '@common/components'

// import {
//   flexCenter,
// } from '@styles/mixins'

const MultiChartSettingsStyled = styled('div')`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-top: 1rem;
  min-height: 12rem;
  > .row {
    display: flex;
    .select {
      width: 100%;
      color: ${p => p.theme.colors.normalFontColor};
    }
  }
`

export default {
  props: {
    widgetDimension: {
      type: Object,
      required: true,
    },
  },
  components: {
    BasicSelect,
    MultiChartSettingsStyled,
  },
  data() {
    return {
      chartTypes: [
        'LINE',
        'COLUMN',
        // 'AREA',
      ],
    }
  },
  computed: {
    selectedGraphType() {
      let type = get(this.widgetDimension, 'graphType', 'LINE')
      return {
        id: type,
        label: this.$t(`graphTypes.${type}`),
      }
    },
    chartTypeOptions() {
      const opt = this.chartTypes.map(type => ({
        id: type,
        label: this.$t(`graphTypes.${type}`),
      }))
      return opt
    },
  },
  methods: {
    updateChartType({ id }) {
      this.$emit('updateChartType', id)
    },
  },
}
</script>
